type Props = {
  children: JSX.Element
}

export default function Cta({ children }: Props) {
  return <section className='cta'>{children}</section>
}

Cta.scheme = {
  render: 'Cta'
}
